import { useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';

/**
 * Custom Hook: useSessionCookie
 *
 * A reusable React hook for managing session cookies using js-cookie.
 * It provides functions to get, set, and remove session cookies.
 *
 * @param {string} key - The name of the session cookie.
 * @returns {{
 *   value: string | undefined,
 *   setCookie: (value: string) => void,
 *   removeCookie: () => void
 * }} - The session cookie value and manipulation functions.
 *
 * @example
 * const { value, setCookie, removeCookie } = useSessionCookie('session_token');
 * setCookie('abc123'); // Sets the session cookie
 * removeCookie();      // Removes the session cookie
 */
const useSessionCookie = (key) => {
  const [value, setValue] = useState(() => Cookies.get(key));

  useEffect(() => {
    const cookieValue = Cookies.get(key);
    if (cookieValue !== value) {
      setValue(cookieValue);
    }
  }, [key, value]);

  /**
   * Sets a session cookie that expires when the browser is closed.
   * Updates state only if the new value is different.
   * @param {string} newValue - The value to store in the cookie.
   */
  const setCookie = useCallback((newValue) => {
    if (value !== newValue) {
        Cookies.set(key, newValue);
        setValue(newValue);
    }
  }, [key, value]);

  /**
   * Gets a session cookie that expires when the browser is closed.
   */
  const getCookie = useCallback(() => {
    return Cookies.get(key);
  }, [key]);

  /**
   * Removes the session cookie.
   */
  const removeCookie = useCallback(() => {
    Cookies.remove(key);
    setValue(undefined);
  }, [key]);

  return { value, setCookie, getCookie, removeCookie };
};

export default useSessionCookie;
