import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

import '../theme/global.scss';
import '../theme/normalize.css';
import { CampaignProvider } from '../components/Campaign/context';
import useSessionCookie from '../hooks/useSessionCookie';

const HeaderComponent = dynamic(() =>
  import('../components/HeaderComponent/Header')
);
const CampaignWrapper = dynamic(() => import('../components/Campaign'), {ssr: false});
const FooterComponent = dynamic(() => import('../components/Footer/Footer'));

function MyApp({ Component, pageProps }) {
  const { setCookie, getCookie, removeCookie } = useSessionCookie('cid_value');

  const router = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (router && router.query && router.query.CID) {
        setCookie(router.query.CID);
      }

      window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
      window.utag_cfg_ovrd.noview = true;

      /**
       * Click event handler to modify outbound links dynamically.
       * @param {MouseEvent} event - The click event object.
       */
      const handleClick = (event) => {
        const target = event.target;

        if (target.href) {
          const url = new URL(target.href);
          const cidValue = getCookie();

          // Check if the link is to Vodacom domains
          if (cidValue && (url.hostname.endsWith('vodacom.co.za') || url.hostname.endsWith('vodacombusiness.co.za'))) {
            url.searchParams.set('CID', getCookie());
            event.preventDefault();
            window.open(url.toString(), '_blank');
          }
        }
      };

      // Attach the event listener to the document
      document.addEventListener('click', handleClick, true);
    }

    // Cleanup function to remove the listener when the component unmounts
    return () => {
      if (typeof window !== 'undefined') document.removeEventListener('click', handleClick, true);
    };
  }, []);

  return (
    <>
      <Script
        id='tealium'
        strategy='beforeInteractive'
        src={`https://tags.tiqcdn.com/utag/vodafone/za-blog/${
          process.env.NODE_ENV === 'production' ? 'prod' : 'dev'
        }/utag.js`}
      />

      <CampaignProvider>
        <HeaderComponent path={router.pathname} />
        <CampaignWrapper />
        <Component {...pageProps} router={router} />
        <FooterComponent path={router.pathname} />
      </CampaignProvider>
    </>
  );
}

export default MyApp;
